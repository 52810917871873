<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div
          class="flex justify-content-space-between align-items marginBottom20"
        >
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="getAddPhone"
            >添加</el-button
          >
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除</el-button
        >
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="form.id ? '编辑' : '新建'"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="servicePhone">
          <el-input
            v-model="form.servicePhone"
            type="text"
            maxlength="20"
            placeholder="请输入手机号"
            clearable
            show-word-limit
           
          />
          <!-- onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')" -->
        </template>
        <template slot="menuForm">
          <!-- <el-button icon="el-icon-close" @click="handleClose">确定</el-button> -->
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  phoneList,
  phoneAdd,
  deletePhone,
  phoneView,
  phoneUpdate,
} from "@/api/setting/index";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        title: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "客服电话号码",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "手机号",
            prop: "servicePhone",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        servicePhone: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "100",
        submitDisabled: true, // 提交按钮默认禁用

        column: [
          {
            label: "手机号",
            prop: "servicePhone",
            span: 24,
            maxlength: 20,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入手机号",
                trigger: "blur",
              },
              // {
              //   validator: (rule, value, callback) => {
              //     if (!/^1[3456789]\d{9}$/.test(value)) {
              //       callback(new Error("请输入正确的手机号"));
              //     } else {
              //       callback();
              //     }
              //   },
              //   trigger: "blur",
              // },
            ],
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    // 新增编辑
    submit() {
      if (this.form.id) {
        phoneUpdate({
          ...this.form,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功!");
              this.onLoad();
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
            } else {
              this.$message.error(res.msg);
              this.$refs.form.allDisabled = false;
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        phoneAdd({
          ...this.form,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功!");
              this.onLoad();
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
            } else {
              this.$message.error(res.msg);
              this.$refs.form.allDisabled = false;
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    onLoad() {
      this.showLoading = true;
      phoneList({
        page: this.page.currentPage,
        size: this.page.pageSize,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    //添加电话号码
    getAddPhone() {
      if(this.tableData && this.tableData.length < 6) {
        this.dialogVisible = true
      } else {
        this.$message({
          type:'warning',
          message:'最多添加6个'
        })
      }
    },
    // 添加
    handleCreate() {
      this.$router.push({
        path: "/home/banner/create",
        query: {
          type: "create",
        },
      });
    },
    handleEdit(row) {
      this.form = row;
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePhone({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        servicePhone: "",
      };
    },
  },
};
</script>

<style scoped></style>
